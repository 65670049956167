import React, { useEffect, useState } from "react";

const Test2 = ({ chunkItem }) => {
	const [allText, setAllText] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState({});
	const [showAnswer, setShowAnswer] = useState(false);
	const [results, setResults] = useState({ correct: 0, incorrect: 0 });

	useEffect(() => {
		if (chunkItem.testContent !== null) {
			getTests(chunkItem.testContent.link);
		}
	}, []);

	const getTests = async (link) => {
		const response = await fetch(link);
		const data = await response.json();
		setAllText(JSON.parse(data));
	};

	const handleOptionSelect = (questionIndex, option) => {
		setSelectedOptions((prevOptions) => ({
			...prevOptions,
			[questionIndex]: option,
		}));
	};

	const checkAnswers = () => {
		let correctCount = 0;
		let incorrectCount = 0;

		allText.forEach((question, index) => {
			if (selectedOptions[index] === question.correctAnswer) {
				correctCount += 1;
			} else {
				incorrectCount += 1;
			}
		});

		setResults({ correct: correctCount, incorrect: incorrectCount });
		setShowAnswer(true);
	};

	return (
		<>
			<div className="m-1">
				<h2 className="bg-green-200 w-fit">
					<strong>Yönerge :</strong> Lütfen aşağıdaki soruları cevaplayınız ve
					teslim et'e tıklayarak çalışmanız kayıt edip cebaplarınızı gözden geçiriniz.
				</h2>
			</div>
			<div className="flex flex-col">
				<div className="flex justify-center">
					<button
						className="p-3 m-2 bg-green-300 rounded-md w-32"
						onClick={checkAnswers}
					>
						Teslim Et
					</button>
				</div>

				{showAnswer && (
					<div className="mt-4 p-4 bg-blue-100 rounded-md text-center">
						<p className="text-lg font-semibold">Sonuçlar</p>
						<p>Toplam Sorular: {allText.length}</p>
						<p>Doğru Cevaplar: {results.correct}</p>
						<p>Yanlış Cevaplar: {results.incorrect}</p>
					</div>
				)}
			</div>
			<div className="flex flex-wrap gap-4 mt-5 justify-center bg-green-50 ml-20">
				{allText.length !== 0 &&
					allText.map((question, questionIndex) => (
						<div
							key={questionIndex}
							className="max-w-md mx-auto my-8 p-4 bg-white shadow-md rounded-md w-96"
						>
							<h2 className="text-xl font-bold mb-4">{question.question} ?</h2>
							<div className="flex flex-col gap-4">
								{question.answers.map((option, index) => (
									<label
										key={`${questionIndex}-${index}`}
										className="flex items-center"
									>
										<input
											type="radio"
											name={`question-${questionIndex}`}
											value={option}
											checked={selectedOptions[questionIndex] === option}
											onChange={() => handleOptionSelect(questionIndex, option)}
											className="mr-2 cursor-pointer"
										/>
										<span>{option}</span>
									</label>
								))}
							</div>
							{showAnswer && (
								<p
									className={`mt-4 ${
										selectedOptions[questionIndex] === question.correctAnswer
											? "text-green-600"
											: "text-red-600"
									}`}
								>
									{selectedOptions[questionIndex] === question.correctAnswer
										? "Doğru!"
										: `Yanlış! Doğru Cevap: ${question.correctAnswer}`}
								</p>
							)}
						</div>
					))}
			</div>
		</>
	);
};

export default Test2;
