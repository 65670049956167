// WriterForm.js
import React, { useState, useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ContentCreator from "./ContentCreator2";
import axios from "axios";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const SummaryContent2 = ({ chunkItem }) => {
	const [title, setTitle] = useState(null);
	const [paragraphs, setParagraphs] = useState([]);
	const [currentInput, setCurrentInput] = useState("");
	const [readingChunkItem, setReadingChunkItem] = useState(null);
	const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [file, setFile] = useState(null);
	const [content, setContent] = useState([]);
	


    const chunkSize = 5 * 1024 * 1024; // 5 MB parçalar


	const handleInputChange = (e) => {
		setCurrentInput(e.target.value);
	};

	const addParagraph = () => {
		if (currentInput.trim() !== "") {
			setParagraphs([...paragraphs, currentInput]);
			setCurrentInput("");
		}
	};

	// const handleContentChange = (e) => {
	// 	setContent(e.target.value);
	// };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

	const handleTitleChange = (e) => {
		setTitle(e.target.value);
	};

	useEffect(() => {
		if (chunkItem.summaryContent !== null) {
			// setLoading(true);
			setReadingChunkItem(chunkItem.summaryContent)
		} else {
			setReadingChunkItem(null);
			setParagraphs([]);
			setTitle(null);
		}
		console.log("Reading content chunkItem");
		console.log(chunkItem);
	}, [chunkItem]);

	const getReading = async (link) => {
		// console.log(`link`);
		// console.log(link);
		// const response = await fetch(link);
		// const data = await response.json();
		// setReadingChunkItem(chunkItem.readingContent);
		// setParagraphs(data);
		 setLoading(false);
	};

    const uploadFile = async () => {
        if (!file) return;

        // 1. Yükleme oturumunu başlat
        const startUploadResponse = await axios.post(`${process.env.REACT_APP_API_URL}/summaryContents/start-upload`, {
            fileName: file.name,
            fileType: file.type
        });
        const { uploadId, key } = startUploadResponse.data;

        const totalChunks = Math.ceil(file.size / chunkSize);
        const uploadedParts = [];

        // 2. Dosyayı parçalara böl ve her bir parçayı yükle
        for (let i = 0; i < totalChunks; i++) {
            const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);

            // Parça için imzalı URL al
            const { data: { url } } = await axios.post(`${process.env.REACT_APP_API_URL}/summaryContents/get-upload-url`, {
                uploadId,
                key,
                partNumber: i + 1
            });

            // Parçayı S3'e yükle
            const uploadResponse = await axios.put(url, chunk, {
                headers: { 'Content-Type': 'application/pdf' },
                onUploadProgress: (progressEvent) => {
                    const chunkProgress = ((i + 1) / totalChunks) * 100;
                    setProgress(chunkProgress);
                }
            });

            // Her parça için ETag'i kaydet
            uploadedParts.push({
                ETag: uploadResponse.headers.etag,
                PartNumber: i + 1
            });
        }

        // 3. Yüklemeyi tamamla
        await axios.post(`${process.env.REACT_APP_API_URL}/summaryContents/complete-upload`, {
            uploadId,
            key,
            parts: uploadedParts,
            title:title,
            chunkItemId:chunkItem.id
        });

        // alert("Yükleme tamamlandı!");
    };

	const handleSubmit = async () => {
		setLoading(true);
		const data = {
			title: title,
			content: paragraphs,
			chunkItemId: chunkItem.id,
		};

		console.log("data handle submit");
		console.log(data);

		fetch(`${process.env.REACT_APP_API_URL}/summaryContents`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((response) => {
				console.log("Success:", response);
				setReadingChunkItem(response);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});

		setLoading(false);
	};

	const deleteHandler = async () => {
		setLoading(true);
		console.log(`id`);

		fetch(`${process.env.REACT_APP_API_URL}/summaryContents/${readingChunkItem.id}`, {
			method: "delete",
			mode: "cors",
			headers: {
				// Authorization: `${authCtx.token}`,
				"Content-Type": "application/json",
			},
			//body: JSON.stringify(data),
		})
			//.then((response) => console.log(response.json()))
			.then((response) => {
				console.log("Success:", response);
				setReadingChunkItem(null);
				setParagraphs([]);
				setTitle(null);
				// chunkItem.videoContent == null
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});

		setLoading(false);
	};

	return (
		<>
			<span className="flex flex-row gap-3">

				<div>Summary Metni</div>
				<div>
					<span>İçerik - </span>
					<span>{chunkItem.orderNo}</span>
				</div>
			</span>
			{loading == true ? (
				<div>Loading</div>
			) : chunkItem.summaryContent !== null ? (
                <>
                <div className='flex bg-red-300 w-fit cursor-pointer' onClick={deleteHandler}>
						Delete
						<span>
							<XMarkIcon className='h-6 w-6' />
						</span>
				</div>
					<div>
                      <embed src={chunkItem.summaryContent.link} width="100%" height="700px" type="application/pdf" />
                    </div>
                </>
			) : (
				<div className='w-[512px] mx-auto mt-8 p-6  rounded-md shadow-md'>
					<ContentCreator setContent={setContent} content={content} />
					<div>
						<label>Title:</label>
						<input
							type='text'
							value={title}
							onChange={handleTitleChange}
							placeholder='lüfen başlık giriniz'
							className='p-2 m-1 bg-green-50'
						/>
					</div>
                    <div>
                <input type="file" onChange={handleFileChange} accept="application/pdf" />
                <button onClick={uploadFile} className="bg-red-300">Upload PDF</button>
                <div style={{ width: '100%', height: '20px', backgroundColor: '#f3f3f3', marginTop: '10px' }}>
                    <div
                      style={{
                          width: `${progress}%`,
                           height: '100%',
                          backgroundColor: progress === 100 ? 'green' : 'blue',
                           transition: 'width 0.2s ease'
                       }}
                    ></div>
                </div>
                <p>{Math.round(progress)}% uploaded</p>
             </div>
             
				</div>
			)}

         
		</>
	);
};

export default SummaryContent2;



