import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { useNavigate } from "react-router-dom";
import { ArrowLongLeftIcon, ArrowLongRightIcon, ClockIcon } from "@heroicons/react/24/solid";
import { useHttp } from "../../hooks/useHttp";

const CourseDetail = ({ course, mine }) => {
	const {
		name,
		photo,
		goals,
		active,
		description,
		totalCompleteTimeType,
		totalCompleteTimeNumber,
		courseTimeNumber,
		courseTimeType,
		chunkTimeNumber,
		chunkTimeType,
		categoryId,
		category,
	} = course;
	const navigate = useNavigate();
	const authCtx = useContext(AuthContext);
	const { data, error, loading, sendRequest } = useHttp();

	const [courseGoals, setGoals] = useState([]);

	const [openUnits, setOpenUnits] = useState(course.sections);

	useEffect(() => {
		console.log("course");
		console.log(course);
		console.log("mine");
		console.log(mine);

		if (course.goals !== null) {
			setGoals(JSON.parse(course.goals));
		} else {
			setGoals([]);
		}
	}, [course]);

	const toggleUnit = (index) => {
		setOpenUnits((prev) => ({ ...prev, [index]: !prev[index] }));
	};

	const [expanded, setExpanded] = useState({
		objectives: false,
		method: false,
		audience: false,
	});

	// Bir kartı açıp kapamak için fonksiyon
	const toggleExpansion = (section) => {
		setExpanded((prev) => ({ ...prev, [section]: !prev[section] }));
	};

	const handleEntrolCourse = async () => {
		const data1 = {
			courseUniqId: course.uniqId,
		};

		console.log(data1);
		console.log(JSON.stringify(data1));
		sendRequest("/userCoursePayments", "POST", data1);

		// 	fetch(`${process.env.REACT_APP_API_URL}/userPayments`, {
		// 		method: "POST",
		// 		mode: "cors",
		// 		headers: {
		// 			Authorization: `${authCtx.token}`,
		// 			"Content-Type": "application/json",
		// 		},
		// 		body: JSON.stringify(data1),
		// 	})
		// 		.then((response) => console.log(response.json()))
		// 		.then((data) => {
		// 			console.log("Success:", data);
		// 			alert(`Basarili bir sekilde kursa kayit olundu.`);
		// 			navigate("/payment");
		// 		})
		// 		.catch((error) => {
		// 			console.error("ErrorBurdan:", error);
		// 		});
	};

	useEffect(() => {
		if (data != null) {
			console.log(data);
			console.log("Success:", data);
			alert(`Basarili bir sekilde kursa kayit olundu.`);
			navigate("/payment");
		}
	}, [data]);

	return (
		<>
			{course != null ? (
				<>
					<div className='container mx-auto p-4'>
						<div className='text-red-500 text-xl'>Kırmızı rakamları takip ederek eğitimi inceleyebilirsin.</div>

						{/* İlk Row */}
						<div className='flex flex-col md:flex-row mb-4'>
							<div className='md:w-1/2 p-2 '>
								<div className='bg-white shadow-lg rounded-lg p-4 h-full'>
									<h2 className='text-2xl font-bold mb-2'>{course.name}</h2>
									<p>{course.description}</p>
								</div>
							</div>
							<div className='md:w-1/2 p-2'>
								<div className='bg-white shadow-lg rounded-lg p-4 h-full'>
									<div className='flex flex-col right-0'>
										<div className=' text-green-500 text-3xl p-2 flex justify-center'>
											{" "}
											TL {course.price !== null ? course.price : "Fiyat yok"}{" "}
										</div>
										{mine ? (
											<button className='bg-blue-500 hover:bg-red-500 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105'>
												Bu kursa kayıtlısınız
											</button>
										) : (
											<button
												onClick={handleEntrolCourse}
												className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105'
											>
												Satın Al
											</button>
										)}
									</div>
								</div>
							</div>
						</div>

						{/* İkinci Row */}
						<div className='flex flex-col md:flex-row mb-4'>
							<div className='md:w-1/3 p-2 flex flex-col'>
								<div className='bg-white shadow-lg rounded-lg p-4 flex flex-col h-full '>
									<h3 className='text-xl font-semibold mb-2'>
										<span className='text-red-500 text-3xl'>1-</span>Kurs Amaçları
									</h3>
									<div className={`${expanded.objectives ? "max-h-full" : "max-h-32"} overflow-hidden`}>
										<ul className='list-disc pl-5'>
											{courseGoals.lenght !== 0 &&
												courseGoals.map((objective, index) => (
													//  <li key={index}>{objective.goal}</li>
													<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
														<span>{objective.goal}</span>
													</div>
												))}
										</ul>
									</div>
									<button
										onClick={() => toggleExpansion("objectives")}
										className='mt-2 text-blue-500 hover:text-blue-700 text-sm font-semibold'
									>
										{expanded.objectives ? "Daha Az Göster" : "Daha Fazla Göster"}
									</button>
								</div>
							</div>
							<div className='md:w-1/3 p-2 flex flex-col'>
								<div className='bg-white shadow-lg rounded-lg p-4 flex flex-col justify-between h-full'>
									<div className={`${expanded.method ? "max-h-full" : "max-h-48"} overflow-hidden`}>
										<h3 className='text-xl font-semibold mb-2'>
											<span className='text-red-500 text-3xl'>2- </span>Çalışma Şekli
										</h3>

										<div>
											<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
												<span>{totalCompleteTimeNumber}</span> <span>{totalCompleteTimeType}</span> tamamlanacaktır.
											</div>
											<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
												Her <span>{courseTimeType}</span> <span>{courseTimeNumber}</span> kere calisilicakitr.
											</div>
											<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
												1 kerede <span>{chunkTimeNumber}</span> <span>{chunkTimeType}</span> calisilicaktir.
											</div>
										</div>
									</div>
									<button
										onClick={() => toggleExpansion("method")}
										className='mt-2 text-blue-500 hover:text-blue-700 text-sm font-semibold'
									>
										{expanded.method ? "Daha Az Göster" : "Daha Fazla Göster"}
									</button>
								</div>
							</div>
							<div className='md:w-1/3 p-2 flex flex-col'>
								<div className='bg-white shadow-lg rounded-lg p-4 flex flex-col h-full justify-between'>
									<div className={`${expanded.audience ? "max-h-full" : "max-h-48"} overflow-hidden`}>
										<h3 className='text-xl font-semibold mb-2'>
											<span className='text-red-500 text-3xl'>3-</span>Kimler Katılabilir
										</h3>
										{/* <p>{course.audience}</p> */}
										<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
											<span>Türkçe okuma yazma bilmeniz yeterlidir.</span>
										</div>
									</div>
									<button
										onClick={() => toggleExpansion("audience")}
										className='mt-2 text-blue-500 hover:text-blue-700 text-sm font-semibold'
									>
										{expanded.audience ? "Daha Az Göster" : "Daha Fazla Göster"}
									</button>
								</div>
							</div>
						</div>

						{/* Üçüncü Row */}
						<div className='flex flex-col'>
							<div className='bg-white shadow-lg rounded-lg p-4'>
								<div className='flex ml-2 sm:ml-10 mb-4 font-semibold text-xl sm:text-2xl md:text-3xl'>
									<span className='text-red-500 '>4- </span>İçerikler
								</div>
								{course.sections.map((section, index) => (
									<div key={index} className='p-2 mb-4 w-fit'>
										<div>
											<h4
												onClick={() => toggleUnit(index)}
												className='text-lg font-semibold cursor-pointer bg-gray-200 p-4 rounded-xl w-full sm:min-w-[300px] sm:w-auto'
											>
												Ünite{" "}
												<span>
													{section.orderNo}: {section.name}
												</span>
											</h4>
										</div>
										{openUnits[index] && (
											<div className="flex flex-col">
												<div className='flex m-2 overflow-hidden'>
													<span className='w-fit sm:min-w-[100px] flex flex-col justify-center bg-red-200 px-1 text-sm rounded-xl '>
														Ünite Kazanım
													</span>
													<span className='flex flex-col  justify-center mx-2'>
														{/* Assuming ArrowLongRightIcon is a component */}
														<ArrowLongRightIcon className='w-4 h-4' />
													</span>
													<span className='pl-3'> {section.description}</span>
												</div>
												<div className='pl-2 sm:pl-5'>
													{section.chunks.map((chunk, idx) => (
														<div
															key={idx}
															className='flex flex-col sm:flex-row my-3 border border-gray-200 rounded-lg p-2 min-w-[100px] overflow-hidden'
														>
															<div className='flex flex-col gap-2 text-xl'>
																<div className='w-full '>
																	{section.orderNo}.{chunk.orderNo}. {chunk.name}
																</div>
																<div className='flex flex-row '>
																	<span className='flex justify-center items-center w-40 p-2 sm:min-w-[100px] bg-red-200 px-1 text-sm rounded-xl'>
																		Konu Kazanım
																	</span>
																	<span className='flex flex-col  justify-center items-center mx-2'>
																		{/* Assuming ArrowLongRightIcon is a component */}
																		<ArrowLongRightIcon className='w-4 h-4' />
																	</span>
																	<span className='w-full sm:min-w-[100px] text-sm flex justify-center items-center'>
																		{chunk.description}
																	</span>
																</div>
															</div>
														</div>
													))}
												</div>
											</div>
										)}
									</div>
								))}
							</div>
						</div>
					</div>

					{/* <div className='flex flex-col'>
						<div className='flex gap-10 pleace-content-around pl-10 border-4 mb-10'>
							<div className='flex flex-col gap-5 font-bold'>
								<div>Kurs Adı : </div>
								<div>Toplam Zaman : </div>
								<div>Calisma sekli : </div>
								<div>Calisma Eforu : </div>
							</div>
							<div className='flex flex-col gap-5 pl-40'>
								<div>{course.name}</div>
								<div>
									{course.totalCompleteTimeNumber} {course.totalCompleteTimeType} tamamlanicak.
								</div>
								<div>
									{course.courseTimeType} {course.courseTimeNumber} kere calisilicak.
								</div>
								<div>
									{" "}
									Bir kerede {course.chunkTimeNumber} {course.chunkTimeType} calisilicak.
								</div>
							</div>
							<div className='flex flex-col flex-auto pt-10 '>
								<button
									className={`border p-2 pl-4 pr-4 bg-green-500 rounded-2xl self-center ${
										mine ? `disabled:opacity-60` : ``
									}`}
									onClick={handleEntrolCourse}
									disabled={mine}
								>
									{!mine ? `Kayit Ol` : `Bu kursa kayitlisiniz`}
								</button>
							</div>
						</div>
						<div className='font-bold pl-10 mb-5'> Genel İçerik</div>
						<div className='border-4'>
							<div>
								<ul className='list-disc ml-10 font-bold'>
									<div>Uniteler</div>
									{course !== null && course.sections.length !== 0 ? (
										course.sections.map((item) => <Section section={item} />)
									) : (
										<div>Bolumler bolumler tanimlanmamis.</div>
									)}
								</ul>
							</div>
						</div>

						<div className='font-bold pl-10 mt-10 mb-5'>Hedef</div>
						<>
							<div className='flex flex-row border-4'>
								{courseGoals.length !== 0 ? (
									<div>
										<ul className='list-disc ml-10 font-bold'>
											{courseGoals.map((item) => (
												<li>{item.goal}</li>
											))}
										</ul>
									</div>
								) : (
									<ul className='list-disc ml-10 font-bold'>
										<li> Kurs hedefleri tanimlanmamistir.</li>
									</ul>
								)}
							</div>
						</>
					</div> */}
				</>
			) : (
				<div>Course pending</div>
			)}
		</>
	);
};

export default CourseDetail;

{
	/* <div>
							<div className='font-bold pl-10'>3 aylik kursun sonunda nitel olarak kazinmlar</div>
							<ul className='list-disc ml-10 font-bold'>
								<li>Ingiliz bastan sona konusmak icin herseyi kapsayan gramer bilgisi</li>
								<li>ingilzice 3000+ kelime (konusmak icin yeterli)</li>
								<li>Zincir egitim sistemi ile guzel bir calisma disipli kazanmis oluyoruz.</li>
								<li>Hedefe varmis olmanin verdigi mutluluk</li>
								<li>Arkadaslarimiza ingilizce biliyoruz diye hava atmak</li>
								<li>Instagramdan yabanci kizlara yuruyebilmek icin gerekli donanimlardan birinin tamamlanmasi</li>
								<li>tinder konumu amerika yapip kiz tavlamak</li>
							</ul>
						</div>

						<div className='font-bold'>
							<div className='pl-10'>3 aylik kursun sonunda nolucek olarak kazanimlar</div>
							<ul className='list-disc ml-10 font-bold'>
								<li>Akici ve Rahat bir sekilde ;</li>
								<li>Rahat bir sekilde yazma</li>
								<li>Rahat bir sekilde anlama</li>
								<li>Rahat bir sekilde KONUSMA aslinda</li>
								<li>sadece konusmayi ogretmek amacimiz ama</li>
								<li>bu okuma yazma anlamada yaninda geliyor iste</li>
								<li>becelerinin elde edilmesi saglanmaktadir</li>
							</ul>
						</div> */
}
