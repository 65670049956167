import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../store/auth-context";
import CourseContent from "../components/TeacherCourse/CourseContent/CourseContent";
import CourseRules from "../components/TeacherCourse/CourseRules";
import CourseStudents from "../components/TeacherCourse/CourseStudents";
import CourseAdd from "../components/TeacherCourse/CourseAdd";
import CourseTek from "../components/TeacherCourse/CourseTek";
import CourseTeacherDetail from "../components/TeacherCourse/CourseTeacherDetail";
import CoursePublish from "../components/TeacherCourse/CoursePublish";

const TeacherCourses = () => {
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState(null);
  const [showPage, setShowPage] = useState(1);
  const [showPage1, setShowPage1] = useState(true);

  useEffect(() => {
    setShowPage(1);
    getCourses();
  }, [showPage1]);

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    console.log(authCtx);
  }, [authCtx]);

  useEffect(() => {
    getCourses();
  }, []);

  const handleCourse = (id) => {
    let selectedCourse = courses.find((item) => item.id == id);
    if (selectedCourse !== undefined) {
      setCourse(selectedCourse);
      setShowPage(1);
    } else {
      setCourse(null);
      setShowPage(0);
    }
  };

  const getCourses = async () => {
    if (authCtx.token !== null) {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/userCourses/user`,
        {
          method: "GET",
          headers: {
            Authorization: `${authCtx.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log("courses");
      console.log(data);
      setCourses(data);
    }
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex-none w-full md:w-52 h-[400px] bg-blue-50 m-2 mt-5 cursor-pointer">
        <div>
          {courses.length === 0 ? (
            <div className="flex flex-row cursor-pointer justify-center gap-2 m-2">
              <div
                onClick={() => setShowPage(6)}
                className="flex flex-col justify-center"
              >
                Lütfen kurs ekleyin
              </div>
              <div
                className="text-2xl px-3 bg-red-300 rounded-xl"
                onClick={() => setShowPage(6)}
              >
                {course != null ? "!" : "+"}
              </div>
            </div>
          ) : (
            <div className="flex flex-col md:flex-row cursor-pointer gap-1">
              <select
                onChange={(e) => handleCourse(e.target.value)}
                className="p-2 border border-green-400 rounded-lg bg-blue-50"
              >
                <option value="0">Lütfen kurs seçiniz</option>
                {courses.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
              <div
                className="text-2xl px-3 bg-red-400 rounded-lg"
                onClick={() => setShowPage(6)}
              >
                {course != null ? "!" : "+"}
              </div>
            </div>
          )}
        </div>
        {course !== null ? (
          <div className="flex flex-col mt-3 justify-center">
            <div
              onClick={() => setShowPage(1)}
              className={`p-1 ${
                showPage === 1 ? "bg-blue-400 cursor-pointer" : "cursor-pointer"
              }`}
            >
              Tanıtım
            </div>
            <div
              onClick={() => setShowPage(2)}
              className={`p-1 ${showPage === 2 ? "bg-blue-400" : ""}`}
            >
              Kapsam
            </div>
            <div
              onClick={() => setShowPage(3)}
              className={`p-1 ${showPage === 3 ? "bg-blue-400" : ""}`}
            >
              İçerikler
            </div>
            <div
              onClick={() => setShowPage(4)}
              className={`p-1 ${showPage === 4 ? "bg-blue-400" : ""}`}
            >
              Kurallar
            </div>
            <div
              onClick={() => setShowPage(5)}
              className={`p-1 ${showPage === 5 ? "bg-blue-400" : ""}`}
            >
              Öğrenciler
            </div>
            <div
              onClick={() => setShowPage(7)}
              className={`p-1 ${showPage === 7 ? "bg-blue-400" : ""}`}
            >
              Yayinlama
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="flex w-full">
        {showPage === 0 && (
          <div className="flex-1 bg-yellow-50">
            <div className="flex flex-row gap-3 justify-center mt-20 font-bold text-2xl">
              <div>Zincir Okula hoş geldiniz</div>
            </div>
          </div>
        )}
        {showPage === 1 && (
          <div className="flex-1 bg-yellow-50">
            {course !== null ? (
              <CourseTeacherDetail course={course} />
            ) : (
              <div className="flex flex-row justify-center mt-20 font-bold text-2xl">
                Zincir Okula hoş geldiniz
              </div>
            )}
          </div>
        )}
        {showPage === 2 && (
          <div className="flex-1 bg-yellow-50">
            {course === null ? (
              <div>Lütfen kurs seçiniz</div>
            ) : (
              <CourseTek course={course} />
            )}
          </div>
        )}
        {showPage === 3 && (
          <div className="w-full bg-yellow-50">
            <CourseContent courseId={course.id} />
          </div>
        )}
        {showPage === 4 && (
          <div className="w-full bg-yellow-50">
            <CourseRules course={course} />
          </div>
        )}
        {showPage === 5 && (
          <div className="w-full bg-yellow-50">
            <CourseStudents courseId={course.id} />
          </div>
        )}
        {showPage === 6 && (
          <div className="w-full bg-yellow-50">
            <CourseAdd
              setState={setShowPage1}
              setCourse={setCourse}
              course={course}
            />
          </div>
        )}
        {showPage === 7 && (
          <div className="w-full bg-yellow-50">
            <CoursePublish setState={setShowPage1} course={course} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TeacherCourses;
