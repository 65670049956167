import React, { useEffect, useState, useContext } from "react";
import VideoContent2 from "./VideoContent2";
import ReadingContent2 from "./ReadingContent2";
import SummaryContent2 from "./SummaryContent2";
import WordContent from "./WordContent";
import TestContent2 from "./TestContent2";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";
import { ArrowRightCircleIcon } from "@heroicons/react/20/solid";

const CourseContentChunkItem = ({ chunkItems }) => {
  // const [chunkItemss, setChunkItemss] = useState(null);
  const [showPage, setShowPage] = useState(0);

  useEffect(() => {
    console.log(`chunkItems`);
    console.log(chunkItems);
    // setChunkItemss(chunkItems);
  }, [chunkItems]);

  const handlePage = (sayi) => {
    console.log(sayi);
    if (sayi == 1) {
      if (showPage + 2 <= chunkItems.length) {
        setShowPage((prevState) => prevState + 1);
      } else {
        setShowPage(0);
      }
    } else if (sayi == -1) {
      if (showPage !== 0) {
        setShowPage((prevState) => prevState - 1);
      } else {
        setShowPage(chunkItems.length - 1);
      }
    }
  };

  return (
    <>
      <div>
        <div className="flex flex-row items-center sm:flex-row">
          {chunkItems.length !== 0 && chunkItems !== null ? (
            chunkItems[showPage].type == "video" ? (
              <div className="flex flex-col w-full justify-center">
                <VideoContent2 chunkItem={chunkItems[showPage]} />
              </div>
            ) : chunkItems[showPage].type == "test" ? (
              <div className="flex flex-col w-full justify-center">
                <TestContent2 chunkItem={chunkItems[showPage]} />
              </div>
            ) : chunkItems[showPage].type == "reading" ? (
              <div className="flex flex-col w-full justify-center" >
                <ReadingContent2 chunkItem={chunkItems[showPage]} />
              </div>
            ) : chunkItems[showPage].type == "word" ? (
              <div className="flex flex-col w-full justify-center">
                <WordContent chunkItem={chunkItems[showPage]} />
              </div>
            ) : chunkItems[showPage].type == "summary" ? (
              <div className="flex flex-col w-full justify-center">
                <SummaryContent2 chunkItem={chunkItems[showPage]} />
              </div>
            ) : (
              <div>There is no type</div>
            )
          ) : (
            <div>Chunk Item yok</div>
          )}
        </div>

        <div className="w-full sm:w-22 flex flex-col sm:flex-row justify-around mt-10 m-2">
          <button
            className="bg-green-200 p-2 w-full sm:w-32 rounded-md mb-2 sm:mb-0"
            onClick={() => {
              handlePage(-1);
            }}
          >
            <div className="flex gap-1">
              <div className="flex flex-col justify-center">
                <ArrowLeftCircleIcon className="iconn" />
              </div>
              <span>Önceki </span>
            </div>
          </button>
          <button
            className="bg-green-200 p-2 w-full sm:w-32 rounded-md"
            onClick={() => {
              handlePage(1);
            }}
          >
            <div className="flex gap-1">
              <span>Sonraki </span>
              <div className="flex flex-col justify-center gap-1">
                <ArrowRightCircleIcon className="iconn" />
              </div>
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default CourseContentChunkItem;
