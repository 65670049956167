import React, { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

const TestContent2 = ({ chunkItem }) => {
	const [questions, setQuestions] = useState([]);
	const [question, setQuestion] = useState("");
	const [answers, setAnswers] = useState([]);
	const [answer, setAnswer] = useState("");
	const [correctAnswer, setCorrectAnswer] = useState("");
	const [questionEdit, setQuestionEdit] = useState(true);

	const [testChunkItem, setTestChunkItem] = useState(null);
	const [loading, setLoading] = useState(false);


	useEffect(() => {
		if (chunkItem.testContent !== null) {
			setLoading(true);
			getTests(chunkItem.testContent.link);
		}
		console.log("Test chunkItem");
		console.log(chunkItem);
	}, []);

	const getTests = async (link) => {
		const response = await fetch(link);
		const data = await response.json();
		setTestChunkItem(chunkItem.testContent);
		setQuestions(JSON.parse(data));
		console.log("data")
		console.log(JSON.parse(data))
		setLoading(false);
	};


	const handleAnswers = () => {
		if (answer) {
			setAnswers([...answers, answer]);
			setAnswer("");
		}
	};

	const handleAnswer = (e) => setAnswer(e.target.value);

	const answerDelete = (answer) => {
		setAnswers(answers.filter((item) => item !== answer));
	};

	const handleCorrectAnswer = (e) => setCorrectAnswer(e.target.value);

	const handleQuestion = (e) => setQuestion(e.target.value);

	const addQuestion = () => {
		if (question && correctAnswer && answers.length) {
			setQuestions([
				...questions,
				{ question, answers, correctAnswer }
			]);
			// Temizleme
			setQuestion("");
			setAnswers([]);
			setCorrectAnswer("");
			setQuestionEdit(true);
		}
	};

	const saveQuestions = async () => {
		const data = {
			title: `Test`,
			content: JSON.stringify(questions),
			chunkItemId: chunkItem.id,
		};

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/testContents`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			});
			const result = await response.json();
			console.log("Success:", result);
			setTestChunkItem(result);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const deleteHandler = async () => {
		setLoading(true);

		fetch(`${process.env.REACT_APP_API_URL}/testContents/${testChunkItem.id}`, {
			method: "delete",
			mode: "cors",
			headers: {
				// Authorization: `${authCtx.token}`,
				"Content-Type": "application/json",
			},
			//body: JSON.stringify(data),
		})
			.then((response) => {
				console.log("Success:", response);
				setTestChunkItem(null);
				setQuestion("");
				setAnswers("");
				setQuestions([]);
				setCorrectAnswer("");
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
		setLoading(false);
	};

	return (


		<>
			{loading == true ? (
				<div>Loading</div>
			) : testChunkItem == null ? (
				<div className="p-4">
					<h2 className="text-xl font-semibold mb-4">Yeni Soru Ekle</h2>
					<div className="flex flex-col gap-4 mt-5 bg-green-50 p-4 rounded-lg">
						<input
							placeholder="Lütfen soru giriniz"
							className="p-2 border rounded"
							value={question}
							onChange={handleQuestion}
						/>
						<div className="flex flex-row gap-2">
							<input
								placeholder="Lütfen cevap giriniz"
								className="p-2 border rounded"
								value={answer}
								onChange={handleAnswer}
							/>
							<button onClick={handleAnswers} className="bg-blue-500 text-white py-2 px-4 rounded">
								Cevap Ekle
							</button>
						</div>

						<div className="flex flex-col gap-2">
							{answers.map((item, index) => (
								<div key={index} className="flex flex-row items-center">
									{item}
									<button onClick={() => answerDelete(item)} className="ml-2 text-red-500">
										<XMarkIcon className="h-4 w-4" />
									</button>
								</div>
							))}
						</div>

						<select
							onChange={handleCorrectAnswer}
							value={correctAnswer}
							className="p-2 border rounded"
						>
							<option value="">Doğru Cevap Seçiniz</option>
							{answers.map((item, index) => (
								<option key={index} value={item}>{item}</option>
							))}
						</select>

						<button
							onClick={addQuestion}
							className="bg-green-500 text-white py-2 px-4 rounded mt-4"
						>
							Soruyu Ekle
						</button>
					</div>

					<div className="mt-6">
						<h3 className="text-lg font-semibold mb-2">Eklenen Sorular</h3>
						<pre className="bg-gray-100 p-4 rounded">{JSON.stringify(questions, null, 2)}</pre>
					</div>

					<button
						onClick={saveQuestions}
						className="bg-red-500 text-white py-2 px-4 rounded mt-4"
					>
						Tüm Soruları Kaydet
					</button>
				</div>
			) : (
				<div className='flex flex-col gap-4 mt-5 justify-center bg-green-50 ml-20'>
					<div className='flex bg-red-300 w-fit cursor-pointer' onClick={deleteHandler}>
						Delete
						<span>
							<XMarkIcon className='h-6 w-6' />
						</span>
					</div>
					<div>
						<span>{chunkItem.orderNo}</span>. içerik
					</div>
					{questions.length !== 0 && (
						questions.map((item) => (
							<div className='flex flex-col justify-center'>

								<div>
									Soru: <span className='font-bold'>{item.question} ?</span>
								</div>
								Cevaplar
								<div>
									{item.answers.map((item) => (
										<div className='flex flex-col gap-2'>
											<div className='flex flex-row gap-3'>
												<input type='checkbox' id={chunkItem.id} />
												{item}
											</div>
										</div>
									))}
								</div>
								<div>
									<span>Dogru Cevap:</span>
									<span>{item.correctAnswer}</span>
								</div>
								<div>
									<span>Ozet:</span>
									<span>{chunkItem.summary}</span>
								</div>
							</div>
						)))}
				</div>
			)}
		</>
	);
};

export default TestContent2;
