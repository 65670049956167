import React, { useContext } from "react";
import Layout2 from "../src/components/Layout2";
import { Routes, Route, useLocation } from "react-router-dom";
import CoursePage from "./pages/CoursePage";
import CoursesByCategoryId from "./pages/CoursesByCategoryId";
import CoursesBySearchKey from "./pages/CoursesBySearchKey";
import ProfilePage from "./pages/ProfilePage";
import MainPage from "./pages/MainPage";
import PaymentPage from "./pages/PaymentPage";
import MentorPage from "./pages/MentorPage";
import CourseDetailPage from "./pages/CourseDetailPage";
import ProfileSettings from "./components/Profile/ProfileSettings";
import TeacherCourses from "./pages/TeacherCourses";
import AuthForm from "./components/Auth/AuthForm";
import CourseProgress2 from "./components/Course/CourseProgress2";
import CourseStart from "./components/Course/CourseStart";
import Footer from "./components/Footer";
import AdminPage from "./pages/AdminPage";
import ConfirmAccountHeader from "./components/AccountSetting/ConfirmAccountHeader";
import ConfirmAccountMail from "./components/AccountSetting/ConfirmAccountMail";
import ForgotPassword from "./components/AccountSetting/ForgotPassword";
import NewPassword from "./components/AccountSetting/NewPassword";
import TeacherApplicationForm from "./components/Admin/TeacherApplicationForm";
import EksDetail from "./components/EKS/EksDetail";
import NotFoundPage from "./pages/NotFoundPage";
import EksPage from "./pages/EksPage";
import ContactPage from "./pages/ContactPage";

function App() {
	if (process.env.NODE_ENV === 'production') {
		console.log('Running in production mode');
	  } else {
		console.log('Running in development mode');
	  }
	const location = useLocation();
	return (
		<div>
			{!location.pathname.includes("/course-start") ? <>
				<ConfirmAccountHeader />
				<Layout2 />
			</> : <></>}

			<div className='min-h-screen'>
				<Routes>
					<Route path='/' element={<MainPage />} />
					<Route path='/auth' element={<AuthForm />} />
					<Route path='/courses' element={<CoursePage />} />
					<Route path='/course/:id' element={<CourseDetailPage />} />
					<Route path='/course-detail/:id' element={<CourseProgress2 />} />
					<Route path='/course-start/:id' element={<CourseStart />} />
					<Route path='/courses/:id/category' element={<CoursesByCategoryId />} />
					<Route path='/courses/:id/search' element={<CoursesBySearchKey />} />
					<Route path='/profile' element={<ProfilePage />} />
					<Route path='/contact' element={<ContactPage />} />
					<Route path='/mentor' element={<MentorPage />} />
					<Route path='/payment' element={<PaymentPage />} />
					<Route path='/profileSettings' element={<ProfileSettings />} />
					<Route path='/teacherCourses' element={<TeacherCourses />} />
					<Route path='/admin' element={<AdminPage />} />
					<Route path='/confirm-account/:id' element={<ConfirmAccountMail />} />
					<Route path='/forgot-password' element={<ForgotPassword />} />
					<Route path='/new-password/:id' element={<NewPassword />} />
					<Route path='/teacher-application' element={<TeacherApplicationForm />} />
					<Route path='/eks' element={<EksPage />} />
					<Route path='/eks/:id' element={<EksDetail />} />
					<Route path='*' element={<NotFoundPage />} />
				</Routes>
			</div>
			{!location.pathname.includes("/course-start") ? <>
			
				<Footer />
			</> : <></>}
			
		</div>
		// <div>Selam AWS</div>
	);
}

export default App;
