import { ArrowDownCircleIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";

const Student = ({ user }) => {
	const [showStudentDetail, setShowStudentDetail] = useState(false);

	return (
		<>
			<div
				className='p-1 border-b border-blue-200 w-fit mx-10 cursor-pointer'
				onClick={() => {
					setShowStudentDetail(!showStudentDetail);
				}}
			>
				<div className="flex flex-row gap-3">
				<span className="flex flex-col justify-center"><ArrowDownCircleIcon className="icon h-5 w-5"/></span>
					<span>{user.name+" "+ user.surname}</span> 
				</div>
				{showStudentDetail && (
					<div className='border border-blue-300 h-auto rounded-lg m-4 '>
						<div>Ceza puani: {user.penaltyPoint}</div>
						<div>Mentorluk aliyor mu: {user.isMentie}</div>
						<div>Mentor mu: {user.penaltyPoint}</div>
						<div>Kurs tamlandi mi: {user.penaltyPoint}</div>
						<div>Mentorluk tamlandi mi: {user.mentoringComplete}</div>
                        <div>---</div>
                        <div>Email: {user.email}</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Student;
