// WriterForm.js
import React, { useState, useEffect } from "react";

const Summary2 = ({ chunkItem }) => {
	useEffect(() => {
		const handleContextMenu = (event) => event.preventDefault();
		const handleKeyDown = (event) => {
			if (event.ctrlKey && (event.key === "s" || event.key === "p")) {
				event.preventDefault();
			}
		};

		document.addEventListener("contextmenu", handleContextMenu);
		document.addEventListener("keydown", handleKeyDown);

		// Temizlik: Bileşen kaldırıldığında olayları kaldır
		return () => {
			document.removeEventListener("contextmenu", handleContextMenu);
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	return (
		<>
			<div className="m-1">
				<h2 className="bg-green-200  w-fit sm:w-1/2">
					<strong>Yönerge :</strong> Lütfen aşağıdaki özet kısmını not edip,
					okuyunuz ve sıradaki faliyette geçiniz. Sıradaki
					faliyette öğrendikleriniz test olarak sınanacaktır. Kopya kağıtlarınız
					hazır olmasında fayda var.
				</h2>
			</div>
			<div className=" justify-center flex flex-row w-full mx-auto shadow-md rounded-md">
				<div className=" w-full">
					{/* <h1 className='text-3xl font-bold mb-4'>{chunkItem.readingContent.title}</h1> */}
					<div className="text-base w-full h-auto">
						{/* <div>
                      <embed src={chunkItem.readingContent.link} width="100%" height="600px" type="application/pdf" />
                    </div> */}
						<div className="m-1">
							<iframe
								src={`${chunkItem.summaryContent.link}#toolbar=0`}
								width="100%"
								height="600px"
								border="10px solid black"
								style={{ border: "none" }}
								title="PDF Görüntüleyici"
							></iframe>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Summary2;
