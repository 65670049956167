import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { XMarkIcon } from "@heroicons/react/24/solid";

const VideoContent2 = ({ chunkItem }) => {
	const [file, setFile] = useState(null);
	const [name, setName] = useState("");
	const fileInputRef = useRef(null);
	const [videoChunkItem, setVideoChunkItem] = useState(null);

	const [uploadId, setUploadId] = useState(null);
	const [parts, setParts] = useState([]);
	const partsRef = useRef([]);
	const chunkSize = 5 * 1024 * 1024; // 5 MB
	const [progress, setProgress] = useState(0); // Progress state
	const [progressToplam, setProgressToplam] = useState(0); // Progress state


	useEffect(() => {
		if (chunkItem.videoContent !== null) {
			setVideoChunkItem(chunkItem.videoContent);
		} else {
			setVideoChunkItem(null)
		}
		console.log(`chunkItem videoContent`);
		console.log(chunkItem.videoContent);
	}, [chunkItem]);

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	const onFileChange = (e) => {
		setFile(e.target.files[0]);
		console.log("e.target.files[0]")
		console.log(e.target.files[0])
	};

	const onNameChange = (e) => {
		setName(e.target.value);
	};

	const deleteHandler = async () => {
		console.log(`id`);
		console.log(chunkItem.videoContent.id);

		fetch(`${process.env.REACT_APP_API_URL}/videoContents/${videoChunkItem.id}`, {
			method: "DELETE",
			mode: "cors",
			headers: {
				// Authorization: `${authCtx.token}`,
				"Content-Type": "application/json",
			},
			//body: JSON.stringify(data),
		})
			//.then((response) => console.log(response.json()))
			.then((response) => {
				console.log("Success:", response);
				setVideoChunkItem(null);
				setName("");
				setFile(null);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};

	//---------------


	const initiateUpload = async () => {
		// const formData = new FormData();
		// formData.append("fileName", file.name);
		// formData.append("fileType", file.type);
		console.log("başladı")
		console.log("file.name")
		console.log(file.name)
		const response = await axios.post(`${process.env.REACT_APP_API_URL}/videoContents/initiate-multipart-upload`,
			{
				fileName: file.name,
				fileType: file.type
			}
		);
		console.log("başladı")
		console.log("response.data.uploadId")
		console.log(response.data.uploadId)

		setUploadId(response.data.uploadId);
		return response.data.uploadId;
	};


	const completeUpload = async (abc) => {

		const response = await axios.post(`${process.env.REACT_APP_API_URL}/videoContents/complete-multipart-upload`, {
			fileName: file.name,
			uploadId: abc,
			parts: partsRef.current.sort((a, b) => a.PartNumber - b.PartNumber),
			chunkItemId: chunkItem.id,
			name: file.name
		});
		// alert("Dosya başarıyla yüklendi!");
		return response;
	};

	const uploadPart = async (chunk, partNumber, abc) => {
		const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/videoContents/generate-presigned-url`,
			{
				fileName: file.name,
				partNumber,
				uploadId: abc
			}
		);
		console.log("data")
		console.log(data)
		const url = data.url;

		const { headers } = await axios.put(url, chunk, {
			headers: {
				'Content-Type': file.type
			},

		});

		partsRef.current.push({ ETag: headers.etag, PartNumber: partNumber });
		console.log("partsRef.length")
		console.log(partsRef)
		console.log("progressToplam")
		console.log(progressToplam)
		const toplam = Math.ceil(file.size / chunkSize)
		setProgress(partsRef.current.length * 100 / toplam);
	};

	const handleUpload = async () => {
		let abc = await initiateUpload();
		console.log("yükleme başladı gitti")


		const promises = [];
		for (let i = 0; i < Math.ceil(file.size / chunkSize); i++) {
			console.log("içeri girdi")

			const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
			promises.push(uploadPart(chunk, i + 1, abc));
			console.log("gitti" + i)
		}
		console.log("promises")
		console.log(promises)
		setProgressToplam(promises.length);

		await Promise.all(promises);
		const rsp = await completeUpload(abc);
		console.log("rsp")
		console.log(rsp)
		setVideoChunkItem(rsp.data);
		alert("Dosya başarıyla yüklendi!");

	};


	// const handleUpload = async () => {
	// 	if (file !== null) {
	// 		const formData = new FormData();
	// 		formData.append("video", file);
	// 		formData.append("name", name);
	// 		formData.append("chunkItemId", chunkItem.id);
	// 		console.log("dosya girdi")
	// 		try {
	// 			const response = await axios.post(`${process.env.REACT_APP_API_URL}/videoContents`, formData, {
	// 				headers: {
	// 					"Content-Type": "multipart/form-data",
	// 				},
	// 			});
	// 			console.log(response);
	// 			setVideoChunkItem(response.data);
	// 		} catch (error) {
	// 			console.error("Error uploading video: ", error);
	// 		}
	// 	} else {
	// 		alert("Lütfen dosya seçiniz");
	// 	}
	// };

	return (
		<>
			{videoChunkItem == null ? (
				<>
					<div className='flex flex-col justify-center w-full m-10 ml-0 sm:m-20'>
						<div className='flex flex-col sm:flex-row gap-2'>
							<div className='font-semibold'>İçerik {chunkItem.orderNo}:</div>
							<span className='font-semibold'>{chunkItem.name}</span>
						</div>
						<div className='bg-red-100 p-5 max-w-xs sm:max-w-sm mx-auto rounded-lg shadow-md'>
							<input
								type='text'
								value={name}
								onChange={onNameChange}
								placeholder='Video Başlığı'
								className='p-2 border border-red-300 rounded w-full mb-3'
							/>
							<input type='file' ref={fileInputRef} onChange={onFileChange} className='hidden' />
							<button
								onClick={handleButtonClick}
								className='mt-3 p-2 bg-red-500 text-white rounded hover:bg-red-600 w-full'
							>
								Dosya Seç
							</button>
							<button
								onClick={handleUpload}
								className='mt-3 p-2 bg-green-500 text-white rounded hover:bg-green-600 w-full'
							>
								Video Yükle
							</button>
							<div style={{ width: '100%', height: '20px', backgroundColor: '#f3f3f3', marginTop: '10px' }}>
								<div
									style={{
										width: `${progress}%`,
										height: '100%',
										backgroundColor: progress === 100 ? 'green' : 'blue',
										transition: 'width 0.2s ease'
									}}
								></div>
							</div>
							<p>{Math.round(progress)}% uploaded</p>
						</div>
					</div>
				</>
			) : (
				<div className="flex flex-row justify-center">
					<div className='flex flex-col gap-4 mt-5 justify-center bg-green-50 sm:ml-20 mx-5 sm:mx-0'>
						<div className='flex bg-red-300 w-fit cursor-pointer p-2 rounded' onClick={deleteHandler}>
							Delete
							<span>
								<XMarkIcon className='h-6 w-6 ml-2' />
							</span>
						</div>
						<div className='flex flex-col sm:flex-row gap-2'>
							<div>Video</div>
							<div className='font-semibold'>{chunkItem.orderNo}-</div>
							Konu: <span className='font-semibold'>{chunkItem.name}</span>
						</div>
						<video className='w-full sm:w-72 h-52' controls>
							<source src={videoChunkItem.url} type='video/mp4' />
							URL kaybolmuş galiba.
						</video>
						<div>
							Video name: <span className='font-semibold'>{videoChunkItem.name}</span>
						</div>
					</div>
				</div>
			)}

		</>
	);
};

export default VideoContent2;
