import React, { useState, useEffect } from "react";

const Kelime = ({ chunkItem }) => {
	const [savedWords, setSavedWords] = useState([]);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (chunkItem.wordContent !== null) {
			setLoading(true);
			getWords(chunkItem.wordContent.link);
		}
		console.log("Word chunkItem");
		console.log(chunkItem);
	}, []);

	const getWords = async (link) => {
		console.log(`link`);
		console.log(link);
		const response = await fetch(link);
		const data = await response.json();
		setSavedWords(JSON.parse(data));
		setLoading(false);
	};
	return (
		<div>
			{loading == true ? (
				<div>Loading</div>
			) : (
				<div className="overflow-x-auto px-4 py-4">
					<h2 className="bg-green-200 w-fit"> 
						<strong>Yönerge :</strong> Lütfen aşağıdaki kelimeleri not edip,
						ezberleyiniz ve sıradaki faliyete geçiniz.
					</h2>
					<h2 className="text-xl font-semibold mt-4 mb-4">Kelimeler</h2>
					<table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
						<thead className="hidden sm:table-header-group">
							<tr className="bg-gray-100">
								<th className="py-2 px-4 border-b text-left text-gray-600 font-semibold">
									Sıra
								</th>
								<th className="py-2 px-4 border-b text-left text-gray-600 font-semibold">
									Kelime
								</th>
								<th className="py-2 px-4 border-b text-left text-gray-600 font-semibold">
									Anlam
								</th>
								<th className="py-2 px-4 border-b text-left text-gray-600 font-semibold">
									Örnek Cümle
								</th>
								<th className="py-2 px-4 border-b text-left text-gray-600 font-semibold">
									Örnek Cümle Anlamı
								</th>
							</tr>
						</thead>
						<tbody>
							{savedWords.map((word, index) => (
								<tr
									key={index}
									className="bg-white border border-gray-300 rounded-md mb-4 p-4 sm:p-0 sm:table-row flex flex-col sm:flex-row hover:bg-gray-50"
								>
									{/* Küçük ekran için özel yapı */}
									<td className="py-2 px-4 sm:hidden">
										<p>
											<strong>{word.word}</strong> ({word.meaning})
										</p>
										<p>
											<strong>{word.exampleSentence}</strong> (
											{word.exampleSentenceMeaning})
										</p>
									</td>

									{/* Diğer ekran boyutları için klasik tablo yapısı */}
									<td className="py-2 px-4 border-b sm:border-0 hidden sm:table-cell">
										{word.order}
									</td>
									<td className="py-2 px-4 border-b sm:border-0 hidden sm:table-cell">
										{word.word}
									</td>
									<td className="py-2 px-4 border-b sm:border-0 hidden sm:table-cell">
										{word.meaning}
									</td>
									<td className="py-2 px-4 border-b sm:border-0 hidden sm:table-cell">
										{word.exampleSentence}
									</td>
									<td className="py-2 px-4 border-b sm:border-0 hidden sm:table-cell">
										{word.exampleSentenceMeaning}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};
export default Kelime;
