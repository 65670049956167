import React, { useEffect, useState, useContext } from "react";
import Video from "./Video";
import Kelime from "./Kelime";
import Test2 from "./Test2";
import Reading2 from "./Reading2";
import Summary2 from "./Summary2";
import { useParams } from "react-router-dom";
import {
	ArrowLongLeftIcon,
	ArrowLongRightIcon,
	ClockIcon,
} from "@heroicons/react/24/solid";
import { Link, useNavigate, NavLink } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import LoadingSpinner from "../UI/LoadingSpinner";

const CourseStart = () => {
	const [currentPage, setCurrentPage] = useState(0);
	const [showFinish, setShowFinish] = useState(false);
	const [showFinishSaved, setShowFinishSaved] = useState(false);
	const [allData, setAllData] = useState({});
	const [chunkAllItems, setChunkAllItems] = useState([]);
	const params = useParams();
	const authCtx = useContext(AuthContext);
	const { id } = params;
	const [secondsRemaining, setSecondsRemaining] = useState(60 * 60);
	const [isVisible, setIsVisible] = useState(true); // Sayaç görünürlüğünü kontrol eden state
	const navigate = useNavigate();

	useEffect(() => {
		console.log(`id`);
		console.log(id);
		getLastChunkItems();
		console.log("window.location");
		console.log(window.location);
	}, [authCtx]);

	useEffect(() => {
		if (secondsRemaining > 0) {
			const intervalId = setInterval(() => {
				setSecondsRemaining((prevSeconds) => prevSeconds - 1);
			}, 1000);

			return () => clearInterval(intervalId); // Temizlik için interval'i temizle
		}
	}, [secondsRemaining]);

	const minutes = Math.floor(secondsRemaining / 60);
	const seconds = secondsRemaining % 60;

	const getLastChunkItems = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/userCourseProgresses/${id}/start`,
			{
				headers: {
					Authorization: `${authCtx.token}`,
					"Content-Type": "application/json",
				},
			}
		);
		const data = await response.json();
		console.log("chunkItems");
		console.log(data);
		setAllData(data);
		setChunkAllItems(
			data.courseUserCourse.sections[0].chunks[0].chunkItems.sort(
				(a, b) => a.orderNo - b.orderNo
			)
		);
	};

	const handlePlusSayfa = () => {
		if (currentPage + 1 < chunkAllItems.length) {
			setCurrentPage((prevPage) => prevPage + 1);
		} else {
			setShowFinish(true);
		}
	};
	const handleMinusSayfa = () => {
		if (currentPage !== 0) {
			setCurrentPage((prevPage) => prevPage - 1);
			setShowFinish(false);
		}
	};

	const handleSaveChunk = () => {
		fetch(
			`${process.env.REACT_APP_API_URL}/userCourseProgresses/${allData.courseId}`,
			{
				method: "PUT",
				mode: "cors",
				headers: {
					Authorization: `${authCtx.token}`,
					"Content-Type": "application/json",
				},
			}
		)
			.then((response) => console.log(response.json()))
			.then((data) => {
				console.log("Success:", data);
				setShowFinishSaved(true);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};

	return (
		<>
			{chunkAllItems.length !== 0 ? (
				<div>
					<div>
						<div>
							<div className="flex flex-row justify-between">
								<div className="flex pt-2 gap-10">
									{/* <div className="font-bold text-lg border p-1 rounded-3xl bg-red-600 self-center">
										Stop
									</div>
									<div className="flex flex-col justify-center">
										<ClockIcon className="w-6 h-6" />
									</div>
									<div className="flex flex-col justify-center">15:00</div> */}
									<div
										onClick={() => navigate("/profile")}
										className="font-bold text-lg p-1 border rounded-3xl bg-red-600 self-center cursor-pointer"
									>
										Exit Work
									</div>
									<div className="flex flex-col justify-center gap-1">
										<div className="flex gap-1">
											<ClockIcon className="w-6 h-6" onClick={() => setIsVisible(!isVisible)}/>
											<div className="pe-16">{isVisible && (
													<div>
														<h1>
															{String(minutes).padStart(2, "0")}:
															{String(seconds).padStart(2, "0")}
														</h1>
														{secondsRemaining === 0 && <p>Süre doldu!</p>}
													</div>
												)}</div>
										</div>
									</div>
								</div>
								<div className="flex pt-2">
									{/* <div
										onClick={() => navigate("/profile")}
										className="font-bold text-lg p-1 border rounded-3xl bg-red-600 self-center"
									>
										Exit Work
									</div>
									<div>
										<ClockIcon className="w-6 h-6" />
										<div className="pe-16">60:00</div>
									</div> */}
									<div>
										<div className="flex flex-row justify-end">
											<button onClick={handleMinusSayfa}>
												<ArrowLongLeftIcon className="w-10 h-10 border rounded-3xl bg-green-500 cursor-pointer" />
											</button>

											<div className="flex self-center text-2xl ">
												{currentPage + 1}/{chunkAllItems.length + 1}
											</div>

											<button onClick={handlePlusSayfa}>
												<ArrowLongRightIcon className="w-10 h-10 border rounded-3xl bg-green-500 cursor-pointer" />
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{showFinish && showFinishSaved ? (
						<div className="flex flex-row justify-center">
							<div className="flex flex-col justify-center w-96 h-32 p-3 m-2 bg-red-300">
								<div className="m-4">Tebrikler Tamamladınız.</div>
								<button
									className="p-3 bg-green-300"
									onClick={() => navigate("/profile")}
								>
									Profil sayfasına git
								</button>
							</div>
						</div>
					) : showFinish ? (
						<div className="flex flex-row justify-center">
							<div className="flex flex-col justify-center w-96 h-32 p-3 m-2 bg-red-300">
								<div className="m-4">Kursu tamladınız. Lütfen kayıt edin.</div>
								<button className="p-3 bg-green-300" onClick={handleSaveChunk}>
									Kaydet
								</button>
							</div>
						</div>
					) : (
						<div className="">
							{chunkAllItems[currentPage].type == "video" && (
								<Video item={chunkAllItems[currentPage]} />
							)}
							{chunkAllItems[currentPage].type == "reading" && (
								<Reading2 chunkItem={chunkAllItems[currentPage]} />
							)}
							{chunkAllItems[currentPage].type == "word" && (
								<Kelime chunkItem={chunkAllItems[currentPage]} />
							)}
							{chunkAllItems[currentPage].type == "summary" && (
								<Summary2 chunkItem={chunkAllItems[currentPage]} />
							)}
							{chunkAllItems[currentPage].type == "test" && (
								<Test2 chunkItem={chunkAllItems[currentPage]} />
							)}
						</div>
					)}
				</div>
			) : (
				<div>
					<LoadingSpinner />
				</div>
			)}
		</>
	);
};

export default CourseStart;
